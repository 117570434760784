.news_wrap{
  padding: 88px 0 113px;
}

.news_title{
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 0.01em;
  color: #446077;
  margin-bottom: 55px;
  text-align: center;
}

.news_slider_wrap{
  margin-bottom: 28px;
}

//.news_slider{
//  width: 100%;
//  display: flex;
//  justify-content: space-between;
//}

.news_slider_item{
  height: 250px;
  padding: 40px;
  background-color: #FFF8EE;
  border: 1px solid #C4C4C4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-right: 29px;
}

.news_slider_item_date{
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #767676;
  margin-bottom: 16px;
}

.news_slider_item_title{
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #1C2677;
  margin-bottom: 16px;
}

.news_slider_item_text{
  font-family: "Lato", sans-serif;
  font-size: 22px;
  line-height: 1.5;
  color: #414141;
}

.news_slider_item_view_more_btn{
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #7383D9;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: auto;
  transition: color .3s;
}

.news_slider_item_view_more_btn:hover{
  color: #5A6DD2;
}

.news_slider_item_view_more_btn img{
  margin-left: 16px;
}

.news_bottom{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.news_bottom_updates{
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: #414141;
}

.news_bottom_updates span{
  font-weight: bold;
}

.news_bottom_slider_controls{
  display: flex;
  align-items: center;
}

.news_bottom_slider_control_btn{
  width: 71px;
  height: 71px;
  background-color: #fff;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color .3s;
}

.news_bottom_slider_control_btn + .news_bottom_slider_control_btn{
  margin-left: 10px;
}

.news_bottom_slider_control_btn:hover{
  background-color: #7383D9;
}

.news_bottom_slider_control_btn.left{
  background-image: url(../../img/news_sl_left_dis.svg);
}

.news_bottom_slider_control_btn.right{
  background-image: url(../../img/news_sl_right_dis.svg);
}

.news_bottom_slider_control_btn.left:hover{
  background-image: url(../../img/news_sl_left.svg);
}

.news_bottom_slider_control_btn.right:hover{
  background-image: url(../../img/news_sl_right.svg);
}

@media screen and (max-width: 1499px){
  .news_wrap{
    padding: 80px 0;
  }

  .news_title{
    font-size: 36px;
    margin-bottom: 40px;
  }

  .news_slider_item{
    height: 220px;
  }

  .news_slider_item_title{
    font-size: 24px;
  }

  .news_slider_item_text{
    font-size: 18px;
  }

  .news_slider_item_view_more_btn{
    font-size: 14px;
  }

  .news_slider_item_view_more_btn img{
    margin-left: 13px;
    width: 21px;
  }

  .news_bottom_updates{
    font-size: 14px;
  }

  .news_bottom_slider_control_btn{
    width: 56px;
    height: 51px;
    background-size: 18px;
  }
}

@media screen and (max-width: 1199px){
  .news_title{
    font-size: 24px;
    margin-bottom: 24px;
  }

  .news_slider_item{
    padding: 20px;
    height: 140px;
  }

  .news_slider_item_date{
    font-size: 10px;
    margin-bottom: 11px;
  }

  .news_slider_item_title{
    font-size: 18px;
    margin-bottom: 11px;
  }

  .news_slider_item_text{
    font-size: 14px;
  }

  .news_slider_item_view_more_btn{
    font-size: 12px;
  }

  .news_slider_item_view_more_btn img{
    width: 16px;
    margin-left: 11px;
  }

  .news_bottom_updates{
    font-size: 12px;
  }

  .news_bottom_slider_control_btn{
    width: 42px;
    height: 42px;
    border-radius: 10px;
    background-size: 17px;
  }
}

@media screen and (max-width: 767px){
  .news_wrap{
    padding: 60px 0;
  }

  .news_slider_wrap{
    margin-left: -10px;
    margin-right: -10px;
  }

  .news_slider_item{
    height: 180px;
    margin: 5px 10px;
  }

  .news_bottom_updates{
    max-width: 135px;
  }
}