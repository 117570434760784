.footer_wrap{
  display: block;
}

.footer_top_wrap{
  padding: 40px 0;
  background-color: #446077;
}

.footer_top{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_logo{
  display: block;
}

.footer_nav{
  display: flex;
  align-items: center;
}

.footer_nav_item{
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none;
}

.footer_nav_item:hover{
  text-decoration: underline;
}

.footer_nav_item + .footer_nav_item{
  margin-left: 86px;
}

.footer_social{
  display: flex;
  align-items: center;
}

.footer_social_item{
  cursor: pointer;
}

.footer_social_item + .footer_social_item{
  margin-left: 17px;
}

.footer_social_item img{
  display: block;
  height: 30px;
}

.footer_bottom_wrap{
  background-color: #698092;
}

.footer_bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 10px;
}

.footer_copy_text{
  display: block;
}

.footer_links{
  display: flex;
  align-items: center;
}

.footer_links_item{
  cursor: pointer;
}

.footer_links_item:hover{
  text-decoration: underline;
}

.footer_links_item + .footer_links_item{
  margin-left: 34px;
}

@media screen and (max-width: 1499px){
  .footer_logo{
    width: 104px;
  }

  .footer_nav_item{
    font-size: 14px;
  }

  .footer_nav_item + .footer_nav_item{
    margin-left: 41px;
  }
}

@media screen and (max-width: 1199px){
  .footer_top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 35px;
  }

  .footer_logo{
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .footer_social{
    margin-left: auto;
  }

  .footer_nav_item + .footer_nav_item{
    margin-left: 24px;
  }

  .footer_nav{
    margin-left: auto;
  }
}

@media screen and (max-width: 767px){
  .footer_top{
    display: block;
  }

  .footer_logo{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 28px;
  }

  .footer_nav{
    justify-content: space-between;
    max-width: 320px;
    margin-bottom: 29px;
    margin-left: auto;
    margin-right: auto;
  }

  .footer_nav_item + .footer_nav_item{
    margin: 0;
  }

  .footer_social{
    justify-content: center;
  }

  .footer_bottom{
    display: block;
    padding: 11px 0;
    height: auto;
  }
  
  .footer_copy_text{
    text-align: center;
    margin-bottom: 11px;
  }

  .footer_links{
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .footer_links_item + .footer_links_item{
    margin: 0;
  }
}