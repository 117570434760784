.registration_wrap{
  padding: 100px 0;
}

.registration{
  display: block;
}

.registration_title{
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 0.01em;
  color: #446077;
  margin-bottom: 5px;
  text-align: center;
}

.registration_desc{
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #333;
  margin-bottom: 50px;
  text-align: center;
}

.registration_form{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.registration_col{
  position: relative;
  width: calc(100% / 3);
}

.registration_col:nth-child(2){
  margin-left: 20px;
  margin-right: 20px;
}

.registration_input_text{
  font-family: "Lato", sans-serif;
  width: 100%;
  display: block;
  height: 70px;
  border: 1px solid rgba(109, 128, 170, 0.8);
  border-radius: 15px;
  font-weight: 500;
  font-size: 24px;
  color: #1C2677;
  outline: none;
  padding-left: 20px;
}

.registration_label_text{
  font-family: "Lato", sans-serif;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  color: #5A6681;
  cursor: text;
  transition: .3s;
}

input:-webkit-autofill  ~ .registration_label_text,
.registration_input_text:focus ~ .registration_label_text,
.registration_input_text:not(:placeholder-shown) ~ .registration_label_text{
  font-size: 11px;
  top: 6px;
  left: 19px;
}

.registration_become_btn{
  font-family: "Lato", sans-serif;
  height: 70px;
  background-color: #7383D9;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color .3s;
}

.registration_become_btn:hover{
  background-color: #5A6DD2;
}

@media screen and (max-width: 1499px){
  .registration_wrap{
    padding: 80px 0;
  }

  .registration_title{
    font-size: 36px;
  }

  .registration_desc{
    font-size: 14px;
    margin-bottom: 40px;
  }

  .registration_input_text{
    height: 53px;
    font-size: 14px;
    padding-top: 4px;
  }

  .registration_label_text{
    top: 18px;
    font-size: 14px;
  }

  .registration_input_text:focus ~ .registration_label_text,
  .registration_input_text:not(:placeholder-shown) ~ .registration_label_text{
    top: 5px;
    font-size: 9px;
  }

  .registration_become_btn{
    height: 53px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1199px){
  .registration_title{
    font-size: 24px;
    margin-bottom: 7px;
  }

  .registration_desc{
    font-size: 12px;
    margin-bottom: 30px;
  }

  .registration_input_text{
    height: 48px;
    border-radius: 10px;
  }

  .registration_label_text{
    top: 15px;
  }

  .registration_become_btn{
    height: 48px;
    font-size: 16px;
    border-radius: 10px;
  }

  .registration_col{
    width: 29%;
  }

  .registration_col:nth-child(2){
    margin: 0 12px;
  }
}

@media screen and (max-width: 767px){
  .registration_wrap{
    padding: 60px 0;
  }

  .registration_desc{
    max-width: 202px;
    margin-left: auto;
    margin-right: auto;
  }

  .registration_form{
    display: block;
  }

  .registration_col{
    width: 100%;
    margin: 0 0 12px;
  }

  .registration_col:nth-child(2){
    margin-left: 0;
    margin-bottom: 12px;
  }
}

.input_label__suggestion {
  display: none;
}

.input_label__suggestion.error_color {
  display: block;
  color: rgb(167, 10, 10);
  position: absolute;
  bottom: -40px;
  font-size: 17px;
}