.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */

.slick-slide div{
  outline: none;
}

.testimonials_global .slick-list{
  padding: 0 240px;
  //display: flex;
}

.testimonials_global .slick-dots{
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

//.testimonials_global .slick-slide{
//  float: none;
//  height: 100%;
//}
//
//.testimonials_global .slick-slide > div{
//  height: 100%;
//}
//
//.testimonials_global .slick-slide > div > div{
//  height: 100%;
//}

.testimonials_global .slick-dots li{
  list-style: none;
  margin: 0 5px;
}

.testimonials_global .slick-dots li button{
  display: block;
  font-size: 0;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #7383D9;
  opacity: .3;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.testimonials_global .slick-dots li.slick-active button{
  opacity: 1;
}

@media screen and (max-width: 1499px){
  .testimonials_global .slick-list{
    padding: 0 120px;
  }

  .testimonials_global .slick-dots{
    margin-top: 40px;
  }
}

@media screen and (max-width: 1199px){
  .testimonials_global .slick-list{
    padding: 0 35px;
  }

  .testimonials_global .slick-dots li button{
    width: 9px;
    height: 9px;
  }

  .testimonials_global .slick-dots li{
    margin: 0 4px;
  }
}

@media screen and (max-width: 767px){
  .testimonials_global .slick-list{
    padding: 0 15px;
  }

  .testimonials_global .slick-dots{
    margin-top: 30px;
  }
}