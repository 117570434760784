.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #fff;
  padding-left: 86px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.header.active{
  background-color: #000;
}

.header_logo img{
  display: block;
}

.header_nav{
  display: flex;
  align-items: center;
}

.header_nav_item{
  height: 98px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px;
  text-decoration: none;
}

.header_nav_item:hover{
  text-decoration: underline;
}

.header_nav_item + .header_nav_item{
  margin-left: 60px;
}

.header_nav_item.sign_up{
  padding: 0 63px;
  background-color: #fff;
  color: #414141;
  font-weight: bold;
  border-radius: 15px 0 0 0;
}

@media screen and (max-width: 1499px){
  .header{
    padding-left: 60px;
  }

  .header_logo img{
    width: 90px;
  }

  .header_nav_item{
    height: 78px;
    font-size: 14px;
  }

  .header_nav_item + .header_nav_item{
    margin-left: 20px;
  }

  .header_nav_item.sign_up{
    padding: 0 29px;
  }
}

@media screen and (max-width: 1199px){
  .header{
    border-width: 1px;
    padding-left: 30px;
  }

  .header_logo img{
    width: 88px;
  }

  .header_nav_item{
    height: 74px;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px){

  .header_nav_item{
    display: none;
    height: 65px;
  }

  .header_nav_item.sign_up{
    display: flex;
    padding: 0 20px;
  }

  .header_logo img{
    width: 74px;
  }
}