.main_wrap{
  background-image: url(../../img/main_bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.main_wrap:before{
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(90.44deg, #1A120F 7.9%, rgba(26, 18, 15, 0) 108.22%);
  position: absolute;
  left: -79px;
  top: 0;
}

.main{
  max-height: 860px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 100px;
}

.main_title{
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.main_text{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  color: #D5DDF0;
  max-width: 1035px;
  margin: 23px 0 54px;
}

.main_text.mob{
  display: none;
}

.main_become_btn{
  font-family: "Lato", sans-serif;
  width: 311px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7383D9;
  border-radius: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color .3s;
}

.main_become_btn:hover{
  background-color: #5A6DD2;
}

@media screen and (max-width: 1749px){
  .main_wrap{
    background-position: right 30% center;
  }

  .main_title{
    max-width: 640px;
    font-size: 36px;
  }

  .main_text{
    max-width: 564px;
    font-size: 14px;
  }

  .main_become_btn{
    width: 246px;
    height: 53px;
    font-size: 18px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1499px){
  .main{
    max-height: 595px;
  }
}

@media screen and (max-width: 1199px){
  .main{
    max-height: 424px;
    padding-top: 76px;
  }

  .main_title{
    font-size: 28px;
    max-width: 380px;
  }

  .main_wrap:before{
    width: 100%;
    left: 0;
    background: linear-gradient(90.44deg, #1A120F 7.9%, rgba(26, 18, 15, 0) 108.22%);
  }

  .main_text.mob{
    display: block;
  }

  .main_text.pc{
    display: none;
  }

  .main_text{
    max-width: 320px;
    margin: 16px 0;
  }

  .main_become_btn{
    width: 182px;
    height: 44px;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px){
  .main{
    max-height: 389px;
    padding-top: 66px;
  }

  .main_title{
    font-size: 24px;
    max-width: 225px;
  }

  .main_text{
    font-size: 12px;
    max-width: 175px;
    margin: 14px 0;
  }
}