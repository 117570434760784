.testimonials{
  padding: 100px 0;
  background-color: #E1E9F7;
}

.testimonials_title{
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 0.01em;
  color: #1C2677;
  text-align: center;
}

.testimonials_slider_wrap{
  margin-top: 40px;
  position: relative;
}

.testimonials_slider{
  display: flex;
}

.testimonials_slider_item{
  width: 705px;
  padding: 45px 40px;
  background: #fff;
  border: 1px solid rgba(109, 128, 170, 0.2);
  border-radius: 15px;
  margin-right: 20px;
}

.testimonials_slider_item_text{
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 150%;
  color: #414141;
  margin-bottom: 5px;
  //overflow: hidden;
  //word-break: break-word;
  //display: -webkit-box;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
  //text-overflow: ellipsis;
}

//.testimonials_slider_item_text:hover{
//  -webkit-line-clamp: initial;
//}

.testimonials_slider_item_bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testimonials_slider_item_name{
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #1C2677;
  margin-bottom: 5px;
}

.testimonials_slider_item_position{
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #767676;
}

.testimonials_slider_item_bottom_quotes{
  width: 85px;
}

.testimonials_slider_control_btn{
  width: 71px;
  height: 71px;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 31px);
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(115, 131, 217, 0.8);
  transition: background-color .3s, border .3s;
}

.testimonials_slider_control_btn:hover{
  background-color: #7383D9;
  border: 1px solid transparent;
}

.testimonials_slider_control_btn.left{
  background-image: url(../../img/news_sl_left_dis.svg);
  left: 177px;
}

.testimonials_slider_control_btn.right{
  background-image: url(../../img/news_sl_right_dis.svg);
  right: 177px;
}

.testimonials_slider_control_btn.left:hover{
  background-image: url(../../img/news_sl_left.svg);
}

.testimonials_slider_control_btn.right:hover{
  background-image: url(../../img/news_sl_right.svg);
}

@media screen and (max-width: 1499px){
  .testimonials_title{
    font-size: 36px;
  }

  .testimonials{
    padding: 80px 0;
  }

  .testimonials_slider_item{
    width: 461px;
  }

  .testimonials_slider_item_text{
    font-size: 14px;
  }

  .testimonials_slider_item_name{
    font-size: 18px;
  }

  .testimonials_slider_control_btn{
    width: 56px;
    height: 50px;
  }

  .testimonials_slider_control_btn.left{
    left: 60px;
  }

  .testimonials_slider_control_btn.right{
    right: 60px;
  }
}

@media screen and (max-width: 1199px){
  .testimonials_title{
    font-size: 24px;
  }

  .testimonials_slider_wrap{
    margin-top: 24px;
  }

  .testimonials_slider_item{
    width: 339px;
    padding: 20px 15px;
  }

  .testimonials_slider_item_text{
    font-size: 12px;
  }

  .testimonials_slider_item_name{
    font-size: 15px;
    margin-bottom: 2px;
  }

  .testimonials_slider_item_position{
    font-size: 10px;
  }

  .testimonials_slider_item_bottom_quotes{
    width: 70px;
  }

  .testimonials_slider_control_btn{
    display: none;
  }
}

@media screen and (max-width: 767px){
  .testimonials{
    padding: 60px 0;
  }

  .testimonials_slider_item{
    width: calc(100vw - 30px);
    margin-right: 7px;
  }
}