.payments_wrap{
  padding: 100px 0;
  background-color: #E1E9F7;
}

.payments{
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
}

.payments_row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.payments_item{
  font-family: "Raleway", sans-serif;
  width: calc(50% - 15px);
  height: 274px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF8EE;
  border: 1px solid #ACABD6;
  border-radius: 15px;
  background-repeat: no-repeat;
  letter-spacing: 0.01em;
  font-weight: bold;
}

.payments_item:nth-child(1){
  background-image: url(../../img/pm_1.svg);
}

.payments_item:nth-child(2){
  background-image: url(../../img/pm_2.svg);
}

.payments_item_title{
  font-size: 37px;
  color: #00317B;
  margin-bottom: 23px;
}

.payments_item_title span{
  font-size: 64px;
}

.payments_item_text{
  font-size: 36px;
  color: #333333;
}

.payments_join_btn{
  font-family: "Lato", sans-serif;
  width: 311px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7383D9;
  border-radius: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin: auto;
  cursor: pointer;
  transition: background-color .3s;
}

.payments_join_btn:hover{
  background-color: #5A6DD2;
}

@media screen and (max-width: 1499px){
  .payments_wrap{
    padding: 80px 0;
  }

  .payments_row{
    margin-bottom: 40px;
  }

  .payments_item{
    height: 225px;
  }

  .payments_item:nth-child(1){
    background-position: right bottom;
  }

  .payments_item:nth-child(2){
    background-position: left bottom;
    background-size: contain;
  }

  .payments_item_title{
    font-size: 24px;
    margin-bottom: 12px;
  }

  .payments_item_title span{
    font-size: 48px;
  }

  .payments_item_text{
    font-size: 24px;
  }

  .payments_join_btn{
    width: 246px;
    height: 53px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1199px){
  .payments{
    max-width: 610px;
  }

  .payments_item{
    height: 152px;
    background-size: contain;
  }

  .payments_item_title{
    font-size: 18px;
  }

  .payments_item_title span{
    font-size: 32px;
  }

  .payments_item_text{
    font-size: 18px;
  }

  .payments_row{
    margin-bottom: 30px;
  }

  .payments_join_btn{
    width: 289px;
    height: 44px;
    font-size: 14px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 767px){
  .payments_wrap{
    padding: 60px 0;
  }

  .payments_row{
    display: block;
  }

  .payments_item{
    width: 100%;
  }

  .payments_item:first-child{
    margin-bottom: 11px;
  }

  .payments_join_btn{
    max-width: 100%;
  }
}