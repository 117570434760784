.about_us_wrap{
  padding: 80px 0;
}

.about_us{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about_us_left{
  width: 44.5%;
}

.about_us_left_title{
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 0.01em;
  color: #446077;
  margin-bottom: 28px;
}

.about_us_left_text{
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 1.4;
  color: #414141;
}

.about_us_right{
  display: flex;
  justify-content: space-between;
  width: 52%;
}

.about_us_right_col{
  display: block;
  width: calc(50% - 22px);
}

.about_us_right_col:nth-child(2){
  padding-top: 100px;
}

.about_us_right_item{
  font-family: "Raleway", sans-serif;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 59px;
  background: linear-gradient(180deg, rgba(255, 235, 210, 0.84) 0%, rgba(255, 235, 210, 0.21) 100%);
  border: 1px solid #F8E4CA;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #5A6681;
}

.about_us_right_item + .about_us_right_item{
  margin-top: 44px;
}

.about_us_right_item_ico{
  margin-bottom: 29px;
}

@media screen and (max-width: 1499px){
  .about_us_left_title{
    font-size: 36px;
    margin-bottom: 21px;
  }

  .about_us_left_text{
    font-size: 18px;
  }

  .about_us_right_item{
    height: auto;
    padding-top: 33px;
    padding-bottom: 33px;
    font-size: 18px;
  }

  .about_us_right_item_ico{
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199px){
  .about_us_left{
    width: 55%;
  }

  .about_us_left_title{
    font-size: 24px;
    margin-bottom: 15px;
  }

  .about_us_left_text{
    font-size: 12px;
  }

  .about_us_right{
    width: 41%;
  }

  .about_us_right_col{
    width: calc(50% - 5px);
  }

  .about_us_right_col:nth-child(2) {
    padding-top: 30px;
  }

  .about_us_right_item{
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 11px;
  }

  .about_us_right_item + .about_us_right_item{
    margin-top: 10px;
  }

  .about_us_right_item_ico{
    width: 29px;
    height: 29px;
    margin-bottom: 11px;
  }
}

@media screen and (max-width: 767px){
  .about_us_wrap{
    padding: 60px 0;
  }

  .about_us{
    display: block;
  }

  .about_us_left{
    width: 100%;
    margin-bottom: 30px;
  }

  .about_us_right{
    width: 100%;
  }
}