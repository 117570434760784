@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container{
  max-width: 1920px;
  padding-left: 177px;
  padding-right: 177px;
  margin-left: auto;
  margin-right: auto;
}

.head_container{
  max-width: 1920px;
  padding-left: 86px;
  padding-right: 86px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1499px){
  .container{
    padding-left: 65px;
    padding-right: 65px;
  }
}

@media screen and (max-width: 1199px){
  .head_container,
  .container{
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px){
  .head_container,
  .container{
    padding-left: 15px;
    padding-right: 15px;
  }
}